<template>
  <div class="ChatBackgroundPopup__main-container">
    <div class="ChatBackgroundPopup__center-container">
      <span class="ChatBackgroundPopup__title"
        >Do you want to remove the background?</span
      >
      <button
        type="button"
        class="ChatBackgroundPopup__back"
        @click="$emit('hidePop')"
      >
        Back
      </button>
      <button
        type="button"
        class="ChatBackgroundPopup__remove"
        @click="$emit('remove')"
      >
        Remove
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChatBackgroundPopup',
};
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheets/components/chat-backgrounds/Popup.scss';
</style>
